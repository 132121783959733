.news-reference-block {
  a {
    > .position-relative {
      min-height: 35rem;
    }
    .img-wrapper {
      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        transition: right .3s cubic-bezier(.21,.71,.79,.96);
      }
      &:before {
        left: 0;
        right: 0;
        background-color: rgba(#000000, .2);
      }
      &:after {
        left: -4rem;
        right: -4rem;
        background-color: $light;
        transform: skewX(-10deg);
      }
      .border-top {
        transition: border-top-color .3s;
      }
      .news--title, .h3, .read-more, .read-more i:before {
        transition: .3s;
      }
      .date {
        transition: .3s;
        transition-delay: 1s;
      }
    }
    &:hover, &:active {
      .img-wrapper {
        &:after {
          right: calc(100% + 4rem);
        }
      }
      .border-top {
        border-top: 1px solid white !important;
        transition: border-top-color .3s;
      }
      .news--title, .h3, .read-more, .read-more i:before {
        color: white!important;
        transition: .2s;
      }
      .date {
        color: white!important;
        transition: .1s;
      }
    }
  }
  &.bg-light {
    a {
      .img-wrapper {
        &:after {
          background-color: white;
        }
      }
    }
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}