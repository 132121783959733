.footer{
  &-top {
    .scrollTop {
      height: 5rem;
      width: 5rem;
      transform: rotate(-90deg);
      i {
        transform: translateY(0);
        transition: .2s transform;
      }
      &:hover {
        i {
          transform: translateX(5px);
        }
      }
    }
    .location-wrapper {
      @include media-breakpoint-up(xxl) {
        .accordion-wrapper:first-of-type {
          .accordion-item {
            &:last-of-type {
              border-bottom: 0 !important;
            }
          }
        }
      }

      .accordion-wrapper {
        &:last-of-type {
          .accordion-item {
            &:last-of-type {
              border-bottom: 0 !important;
            }
          }
        }

        .accordion-item {
          .accordion-title {
            i:before {
              color: white;
              display: inline-block;
              transform: rotate(-90deg);
              transition: transform .2s;
            }
            &:not(.collapsed) {
              i:before {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .data-wrapper {
        background-color: $light;
      }
      .location-wrapper {
        background-color: $primary;
      }
    }
    .icon-marker {
      font-size: 2rem;
    }
    .icon-tel {
      font-size: 1.8rem;
    }
    .icon-email {
      font-size: 1.6rem;
    }
  }
  &-bottom {
    a, p, * {
      color: #AAAAAA;
      font-size: 1.4rem;
    }
    a {
      &:hover {
        color: lighten(#AAAAAA,10%) !important;
      }
    }
  }
}