@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('./Barlow-BoldItalic.ttf') format('truetype'),
  url('./Barlow-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 900;
  src: local(''),
  url('./Barlow-BlackItalic.ttf') format('truetype'),
  url('./Barlow-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./Manrope-Regular.ttf') format('truetype'),
  url('./Manrope-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('./Manrope-Medium.ttf') format('truetype'),
  url('./Manrope-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('./Manrope-SemiBold.ttf') format('truetype'),
  url('./Manrope-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('./Manrope-Bold.ttf') format('truetype'),
  url('./Manrope-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('./Manrope-ExtraBold.ttf') format('truetype'),
  url('./Manrope-ExtraBold.woff') format('woff');
}