.ff-body {
  font-family: $font-family-sans-serif;
}

.outline-none {
  outline: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.line-height-092 {
  line-height: .92;
}

.line-height-1 {
  line-height: 1;
}

.line-height-12 {
  line-height: 1.2;
}

.line-height-135 {
  line-height: 1.35;
}

.line-height-heading {
  line-height: $headings-line-height;
}

.position-sm-absolute {
  @include media-breakpoint-up(sm) {
    position: absolute!important;
  }
}

.position-md-absolute {
  @include media-breakpoint-up(md) {
    position: absolute!important;
  }
}

.position-lg-absolute {
  @include media-breakpoint-up(lg) {
    position: absolute!important;
  }
}

.tt-unset {
  text-transform: none!important;
}

.overflow-unset {
  overflow: unset;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-extrabold {
  font-weight: 800;
}

.fw-black-i {
  font-weight: 900;
  font-style: italic;
}

.fs-unset {
  font-style: normal!important;
}

.m-min-1 {
  margin: -1rem;
}

.vh-100 {
  @include media-breakpoint-between(sm,lg) {
    height: 80rem!important;
  }
  @include media-breakpoint-down(sm) {
    height: 60rem!important;
  }
}

.fs-14, .fs-14 p {
  font-size: 1.4rem;
}

.fs-17, .fs-17 p {
  font-size: 1.7rem;
}

.fs-18, .fs-18 p {
  font-size: 1.8rem;
}

.fs-19, .fs-19 p {
  @include media-breakpoint-up(sm) {
    font-size: 1.9rem;
  }
}

.fs-20, .fs-20 p {
  font-size: 1.8rem;
  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
}

.fs-23, .fs-23 p {
  font-size: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 2.3rem;
  }
}

.fs-24, .fs-24 p {
  font-size: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 2.4rem;
  }
}

.fs-30, .fs-30 p {
  font-size: 2.4rem;
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-between(sm,lg) {
    font-size: 2.6rem;
  }
}

.fs-35, .fs-35 p {
  font-size: 2.8rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }
  @include media-breakpoint-between(sm,lg) {
    font-size: 3rem;
  }
}

.fs-60, .fs-60 p {
  font-size: 4rem;
  @include media-breakpoint-up(sm) {
    font-size: 6rem;
  }
}

.fs-75, .fs-75 p {
  @include media-breakpoint-up(lg) {
    font-size: 7.5rem;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: 6.2rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 4.6rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 3.8rem;
  }
}

.fs-88, .fs-88 p {
  @include media-breakpoint-up(xxl) {
    font-size: 8.8rem;
  }
  @include media-breakpoint-between(lg, xxl) {
    font-size: 7.2rem;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: 6.8rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 4.2rem;
  }
}

.fs-128, .fs-128 p {
  @include media-breakpoint-up(xxl) {
    font-size: 12.8rem;
  }
  @include media-breakpoint-between(lg, xxl) {
    font-size: 12rem;
  }
  @include media-breakpoint-between(md, xxl) {
    font-size: 10rem;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 8rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 5rem;
  }
}

.z-index-2 {
  z-index: 2;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.all-0 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.visibility-0 {
  visibility: hidden;
}

.overlay-darken {
  position: relative;
  display: block;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: black;
    opacity: .2;
  }
}

.bg-img-set {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-light-lines {
  position: relative;
  overflow: hidden;
  &:after, &:before {
    content: '';
    background-color: rgba($body-color, .1);
    position: absolute;
    bottom: 8rem;
    height: .1rem;
    width: 100vw;
  }
  &:before {
    left: -20vw;
  }
  &:after {
    left: -44vw;
    transform: rotate(64deg);
  }
}

.r {
  &-90 {
    transform: rotate(90deg);
    transform-origin: center;
    display: inline-block;
  }
  &-180 {
    transform: rotate(180deg);
    transform-origin: center;
    display: inline-block;
  }
}

.white-icon {
  &:before {
    color: white;
  }
}

.history-swiper {
  @include media-breakpoint-up(lg) {
    .swiper-slide {
      width: auto !important;

      &.text-slide {
        max-width: 39rem;
      }
    }
  }
}

.triangle-end {
  position: relative;
  &:after {
    content: url('/web/triangle.svg');
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    aspect-ratio: 1;
  }
}

.small-icon {
  font-size: 1.4rem;
  color: #E8DDD2;
}

.body-icon {
  &:before {
    color: $body-color;
  }
}

.border-primary-end {
  @include media-breakpoint-up(lg) {
    border-right: 1px solid $primary;
  }
}

.border-primary-start {
  border-left: 1px solid $primary;
}

.border-top {
  border-top: 1px solid rgba(222, 226, 230, .6) !important;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-feedback {
  display: block!important;
}

.bg-loader {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: $light;
  background: linear-gradient(to right, lighten($light, 2%) 8%, $light 38%, lighten($light, 2%) 54%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -500px 0
  }
  100%{
    background-position: 500px 0
  }
}

@for $i from 1 through 20 {
  .zi-#{$i} {
    z-index: #{$i};
  }

  .m-min-#{$i} {
    margin: -#{$i}rem;
  }
}