h1, h2 {
  text-transform: uppercase;
  -webkit-text-fill-color: $primary;

  em {
    @include media-breakpoint-up(sm) {
      -webkit-text-stroke: .2rem $primary;
    }
    @include media-breakpoint-down(sm) {
      -webkit-text-stroke: .1rem $primary;
    }
    -webkit-text-fill-color: transparent;
  }

  &.text-white {
    -webkit-text-fill-color: white;
    em {
      @include media-breakpoint-up(sm) {
        -webkit-text-stroke: .2rem white;
      }
      @include media-breakpoint-down(sm) {
        -webkit-text-stroke: .1rem white;
      }
    }
  }
  &.text-body {
    -webkit-text-fill-color: $body-color;
    em {
      @include media-breakpoint-up(sm) {
        -webkit-text-stroke: .2rem $body-color;
      }
      @include media-breakpoint-down(sm) {
        -webkit-text-stroke: .1rem $body-color;
      }
    }
  }
  @include media-breakpoint-down(md) {
    font-size: 4rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 3.6rem;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}

.text-primary-outline {
  -webkit-text-stroke: .2rem $primary;
  -webkit-text-fill-color: transparent;
  transition: -webkit-text-stroke .2s, -webkit-text-fill-color .2s;
}

a {
  &.text-primary-outline {
    &:hover {
      -webkit-text-fill-color: $light;
    }
  }
}

p {
  letter-spacing: .22px;
}