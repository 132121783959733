.btn {
  // not in _vars because top and bottom not equal
  &:not(.btn-sm) {
    padding: 1.3rem 5rem 1.8rem 3rem;
  }
  &-sm {
    padding: .8rem 3.4rem 1rem 1.4rem;
  }
  &-primary {
    position: relative;
    text-transform: uppercase;
    background-color: transparent;
    color: $primary!important;
    border-color: $primary!important;
    border-right: 0!important;
    border-bottom: 0!important;
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
    }
    &:before {
      right: 0;
      top: 0;
      left: calc(100% - 3rem);
      background:
              linear-gradient(to top left,
                      rgba(255,255,255,1) 0%,
                      rgba(255,255,255,1) calc(50% - .15rem),
                      $primary calc(50% - .01rem),
                      $primary 50%,
                      $primary calc(50% + .05rem),
                      rgba(0,0,0,0) calc(50% + .15rem),
                      rgba(0,0,0,0) 100%);
    }
    &:after {
      left: 0;
      height: .2rem;
      width: calc(100% - 3rem);
      background-color: $primary;
    }
    &:hover {
      color: white!important;
      background-color: $primary!important;
    }
    > span {
      &:before {
        color: white!important;
      }
    }
  }
  &-secondary {
    background-color: transparent!important;
    color: $body-color!important;
    border: 0 !important;
    padding: 0 2rem;
    font-family: $font-family-sans-serif;
    font-size: 1.9rem;
  }
  &-white {
    position: relative;
    text-transform: uppercase;
    background-color: transparent;
    color: white;
    border-color: white!important;
    border-right: 0!important;
    border-bottom: 0!important;
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
    }
    &:before {
      right: 0;
      top: 0;
      left: calc(100% - 3rem);
      background:
              linear-gradient(to top left,
                      rgba($primary,1) 0%,
                      rgba($primary,1) calc(50% - .15rem),
                      white calc(50% - .01rem),
                      white 50%,
                      white calc(50% + .05rem),
                      rgba($primary,0) calc(50% + .15rem),
                      rgba($primary,0) 100%);
    }
    &:after {
      left: 0;
      height: .2rem;
      width: calc(100% - 3rem);
      background-color: white;
    }
    &:hover {
      color: $primary!important;
      background-color: white!important;
    }
  }
  &-filter {
    text-transform: uppercase;
    background-color: transparent;
    border-color: transparent;
    color: $primary!important;
    padding: 1.3rem 2rem 1.6rem !important;
    &:hover, &.active {
      background-color: transparent!important;
      border-color: $primary;
    }
  }
  &-item {
    padding: 0!important;
    font-size: 1.6rem;
    font-style: unset;
    font-family: Manrope, sans-serif;
    .icon-arrow {
      font-size: 1.4rem;
      display: inline-block;
      opacity: 1!important;
      transform: translateY(1px) !important;
    }
  }

  .icon-arrow {
    font-size: 1.8rem;
    padding-left: 0;
    opacity: 0;
    transform: translateX(-8px);
    transition: .3s
  }
  &:not(.btn-item):hover {
    .icon-arrow {
      padding-left: 2rem;
      max-width: 10rem!important;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.bg-light {
  .btn {
    &-primary {
      &:before {
        background:
                linear-gradient(to top left,
                        rgba($light,1) 0%,
                        rgba($light,1) calc(50% - .15rem),
                        $primary calc(50% - .01rem),
                        $primary 50%,
                        $primary calc(50% + .05rem),
                        rgba($light,0) calc(50% + .15rem),
                        rgba($light,0) 100%);
      }
    }
  }
}