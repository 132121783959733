form {
  .input-group {
    border-bottom: 1px solid $border-color;
  }
  textarea {
    height: 8.7rem!important;
  }
  .invalid-feedback {
    @include media-breakpoint-up(sm) {
      position: absolute;
    }
    width: auto;
    right: 0;
    top: -4px;
    background-color: white;
    color: $primary!important;
    padding: .4rem 1rem!important;
  }
}