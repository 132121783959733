.swiper-pagination-progressbar {
  height: 2px;
  top: -1px;
  left: 0;
  width: 100%;
  background-color: transparent;
  .swiper-pagination-progressbar-fill {
    background-color: $light;
  }
}

.swiper-pagination-wrapper {
  position: relative;
  &:after {
    content: '';
    background-color: white;
    position: absolute;
    top: -.2rem;
    bottom: -.2rem;
  }
  &-right {
    &:after {
      right: 0;
      @include media-breakpoint-down(sm) {
        right: -1rem;
      }
      left: -2.5rem;
      transform: skewX(-19deg);
    }
  }
  &-left {
    &:after {
      left: 0;
      @include media-breakpoint-down(sm) {
        left: -1rem;
      }
      right: -2.5rem;
      transform: skewX(19deg);
    }
  }
  .swiper-pagination-bullets {
    position: unset!important;
    .swiper-pagination-bullet {
      height: 1.6rem;
      width: 1.2rem;
      border-radius: 0;
      border: 0.2rem solid #F04A00;
      background-color: transparent;
      margin: 0 0.3rem;
      opacity: 1;
      font-size: 0;
      transform: skewX(-22deg);
      transition: background-color .2s;
      &-active, &:hover {
        background-color: $primary;
      }
    }
  }
}

.custom-swiper-arrows {
  > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    i {
      padding: 1.6rem 1.4rem;
      background-color: $primary;
      &:before {
        color: white;
      }
    }

    &.swiper-prev {
      left: 0;
      i {
        display: block;
        transform: rotate(180deg);
      }
    }
    &.swiper-next {
      right: 0;
    }
  }
}

.image-slider--swiper {
  @include media-breakpoint-down(sm) {
    .border-top {
      border: none !important;
    }
  }
}

.swiper-slide {
  height: auto;
}

.swiper-button-disabled {
  opacity: .3;
  pointer-events: none;
}