.activities-block {
  a {
    overflow: hidden;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
    }
    &:after {
      left: -7rem;
      right: calc(100% + 7rem);
      background-color: $primary;
      transform: skewX(-10deg);
      transition: right .25s;
      z-index: 2;
    }
    &:before {
      left: 0;
      right: 0;
      z-index: 1;
      background-color: rgba($dark, .1);
    }
    .card-body {
      opacity: 1!important;
      visibility: unset!important;
      .btn-wrapper {
        border-top: .1rem solid white;
      }
    }
    @include media-breakpoint-down(lg) {
      &:after {
        right: -7rem;
        background-color: rgba($dark, .4);
      }
      .card-body {
        opacity: 1!important;
        visibility: unset!important;
      }
    }
    &:hover {
      &:after {
        right: -7rem;
      }
    }
  }
}