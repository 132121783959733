.pagetitle {
  .gsap-img-wrapper {
    &:before {
      content:'';
      background: rgb(0, 0, 0);
      background: linear-gradient(180deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
    }
  }

  .img-wrapper {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(black, .1);
      z-index: 2;
    }
  }

  .entry-title {
    &-wrapper {
      @include media-breakpoint-down(lg) {
        background-color: $light;
      }
    }
    @include media-breakpoint-up(md) {
      max-width: 80%;
    }
    @include media-breakpoint-down(lg) {
      min-width: 80%;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      left: -100vw;
      background-color: $primary;
    }
  }
}