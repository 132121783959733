.collapse-block {
  .accordion {
    .accordion-item {
      border-bottom: 1px solid $border-color;
      .accordion-title {
        position: relative;
        h3 {
          transition: color .2s;
        }
        &:hover {
          h3, &:after {
            color: $primary!important;
          }
        }
        &:after {
          content: "\e900";
          font-family: "icomoon";
          font-size: 1.2rem;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #F04A00;
          transform: rotate(90deg);
          transition: color 0.2s, transform 0.2s;
        }
        &[aria-expanded=false] {
          &:after {
            color: #000000;
            transform: rotate(0deg);
          }
        }
        &[aria-expanded=true] {
          h3 {
            color: $primary!important;
          }
        }
      }
    }
  }
}