$gray-100:    #FCF5EF;
$body-color:  #000000;
$gray-900:    #000000;

$primary:     #F04A00;
$secondary:   #FE492A;

// Grid columns
$grid-gutter-width: 40px;

// Grid containers
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 100%,
        xxl: 1640px + $grid-gutter-width,
);

// Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1580px
);

// Fonts
$font-size-base:              1.6rem;
$font-weight-base:            400;
$font-family-sans-serif:      'Manrope', sans-serif;
$font-family-monospace:       $font-family-sans-serif;

// Links
$link-color:                              #000;
$link-decoration:                         none;
$link-hover-color:                        #3c3c3c;

// Typography
$line-height-base:          1.62;

// Headings
$headings-font-family:        'Barlow', sans-serif;
$headings-margin-bottom:      4rem;
$headings-font-weight:        900;
$headings-font-style:         italic;
$headings-color:              $primary;
$headings-line-height:        1.09;

$paragraph-margin-bottom:   3rem;

$h1-font-size:                5.5rem;
$h2-font-size:                5.5rem;
$h3-font-size:                3rem;
//$h4-font-size:                $font-size-base * 1.5 !default;
//$h5-font-size:                $font-size-base * 1.25 !default;
//$h6-font-size:                $font-size-base !default;

$enable-validation-icons:     false;
$enable-rfs:                  false;

// Spacing
$spacer: 1rem;
$spacers: (
        0: 0,
        50: 5px,
        1: $spacer,
        150: $spacer * 1.5,
        2: $spacer * 2,
        250: $spacer * 2.5,
        3: $spacer * 3,
        350: $spacer * 3.5,
        4: $spacer * 4,
        450: $spacer * 4.5,
        5: $spacer * 5,
        550: $spacer * 5.5,
        6: $spacer * 6,
        650: $spacer * 6.5,
        7: $spacer * 7,
        750: $spacer * 7.5,
        8: $spacer * 8,
        9: $spacer * 9,
        10: $spacer * 10,
        11:	$spacer * 11,
        1150:	$spacer * 11.5,
        12: $spacer * 12,
        13: $spacer * 13,
        135: $spacer * 13.5,
        14: $spacer * 14,
        15: $spacer * 15,
        155: $spacer * 15.5,
        16: $spacer * 16,
        17: $spacer * 17,
        18: $spacer * 18,
        185: $spacer * 18.5,
        19: $spacer * 19,
        20: $spacer * 20,
        22: $spacer * 22,
        24: $spacer * 24,
);

$border-radius: 0;
$border-color:  #F0EAE4;

$input-btn-padding-y:         1.6rem;
$input-btn-padding-x:         0;
$input-btn-line-height:       1;
$input-btn-focus-color-opacity: 0;
$input-btn-focus-box-shadow:    unset;
$input-btn-border-width:      0;
$input-bg:                    transparent;

$form-check-input-checked-color:          $primary;
$form-check-input-checked-bg-color:       white;

$form-label-color:              white;
$btn-padding-y:               .8rem;
$btn-padding-x:               1.4rem;
$btn-font-size:               2.1rem;
$btn-font-family:             $headings-font-family;
//$btn-white-space:             null;
$btn-border-width:            .2rem;
$btn-font-weight:             700;
$btn-box-shadow:              none;
$btn-focus-width:             none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;
$btn-link-color:              transparent;
$btn-link-hover-color:        white;
$btn-link-disabled-color:     lighten($primary,20%);

$accordion-padding-y:                     3rem !default;
$accordion-padding-x:                     0;
$accordion-bg:                            transparent;
$accordion-border-width:                  0;

$accordion-body-padding-y:                0;
$accordion-body-padding-x:                0;

//$accordion-button-padding-y:              $accordion-padding-y !default;
//$accordion-button-padding-x:              $accordion-padding-x !default;
//$accordion-button-color:                  $accordion-color !default;
//$accordion-button-bg:                     $accordion-bg !default;
//$accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           $primary;

$accordion-button-focus-border-color:     none;
$accordion-button-focus-box-shadow:       none;

$accordion-icon-width:                    1.4rem;
$accordion-icon-color:                    #E8DDD2;
//$accordion-icon-active-color:             $accordion-button-active-color !default;
//$accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(0)!important;

$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-icon:         $accordion-button-active-icon;

$form-floating-label-transform:   scale(.85) translateY(-2rem) translateX(.15rem);
$form-floating-height:            4rem;

$card-border-width:                 0;
$card-bg:                           $gray-100;

$pagination-padding-y:              .4rem;
$pagination-padding-x:              1rem;
$pagination-bg:                     transparent;
$pagination-border-width:           0;
$pagination-active-bg:              $pagination-bg;
$pagination-hover-bg:               $pagination-bg;
$pagination-focus-bg:               $pagination-bg;

:root {
  --fui-alert-font-size: 1.7rem!important;
  --fui-alert-line-height: 1.62!important;
  --fui-border-radius: 0!important;
  --fui-alert-margin-bottom: 2rem!important;
}