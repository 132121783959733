.content-slider--block {
  .simpleParallax {
    height: 100%;
  }
  @include media-breakpoint-up(xl) {
    .data-wrapper {
      &--counter {
        border-right: 1px solid white;
        font-size: 1.6rem;
        font-style: italic;
        font-weight: 700;
        padding-top: 0.2rem;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &-notitle {
      .img-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        .content-slider--imgs {
          height: 100%;
        }
      }
    }
    .data-wrapper {
      z-index: 2;
      .content-slider--content {
        &.bg-primary {
          background-color: rgba($primary, .9) !important;
        }
      }
    }
  }
  &-title {
    .accordion {
      .accordion-item {
        border-top: 1px solid $border-color !important;
        &:not(:last-of-type) {
          border-bottom: 0 !important;
        }
        &:last-of-type {
          border-bottom: 1px solid $border-color !important;
        }

        div[data-bs-toggle=collapse] {
          h3 {
            font-size: 1.9rem;
            font-weight: 500;
            transition: color .2s;
            position: relative;
            &:after {
              content: '\e900';
              font-family: 'icomoon';
              font-size: 1.2rem;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $primary;
              transform: rotate(90deg);
              transition: color .2s, transform .2s;
            }
          }
          &[aria-expanded=false] {
            h3 {
              color: $body-color;
              &:after {
                color: $body-color;
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }
  }
  .img-wrapper {
    max-height: 100rem;
  }
}