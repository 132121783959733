.text-block {
  .heading-text-block {
    p {
      color: $primary;
      font-weight: 700;
      font-size: 2.3rem;
    }
  }

  .departments {
    p {
      font-weight: 700;
      font-size: 2.3rem;
    }
  }
}