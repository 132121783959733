@import "fonts/fonts";

/* BOOTSTRAP OVERWRITE */
@import "vars";
@import "~bootstrap/scss/bootstrap";

// Icons
@import "icomoon/style.css";

// Packages
@import "_hamburgers";
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";
@import '~swiper/swiper-bundle.css';

// Inc
@import "_inc/pagetitle";

// Custom
@import "custom";
@import "gdpr";
@import "swiper";
@import "header";
@import "footer";
@import "nav";
@import "helpers";
@import "buttons";
@import "form";
@import "popup";

// Components
@import "./components/form";
@import "./components/text";
@import "./components/collapse";

@import "./references/index";

//    Blocks
@import "./_blocks/home/homeBanner";
@import "./_blocks/text";
@import "./_blocks/textImage";
@import "./_blocks/contentimagecollapse";
@import "./_blocks/news";
@import "./_blocks/quote";
@import "./_blocks/videos";
@import "./_blocks/numbers";
@import "./_blocks/activities";
@import "./_blocks/vacancies";
@import "./_blocks/certificates";