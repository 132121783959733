#popup {
  button {
    -webkit-appearance: unset;
    background-color: transparent;
    color: $primary!important;
    font-size: 4rem;
    border: 0;
    box-shadow: none!important;
    outline: none!important;
    height: 0;
    > span {
      display: block;
      transform: translateY(-2.7rem);
    }
  }
}