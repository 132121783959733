#gdpr {
  p {
    color: $body-color!important;
  }
  .cookieConsentOperations__Item {
    input:checked + label {
      &:before {
        background: #81ee0b!important;
      }
    }
  }
  .cookieConsent__Right {
    display: flex;
    flex-wrap: wrap;
    button {
      padding: 1.3rem 3.5rem 1.8rem 1.5rem;
    }
  }
}