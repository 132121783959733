@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?48foz5');
  src:  url('fonts/icomoon.eot?48foz5#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?48foz5') format('truetype'),
    url('fonts/icomoon.woff?48foz5') format('woff'),
    url('fonts/icomoon.svg?48foz5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-time:before {
  content: "\e90a";
  color: #f04a00;
}
.icon-x:before {
  content: "\e92b";
}
.icon-book:before {
  content: "\e909";
  color: #f04a00;
}
.icon-crane:before {
  content: "\e905";
}
.icon-d:before {
  content: "\e906";
  color: #ff4800;
}
.icon-play:before {
  content: "\e907";
  color: #fff;
}
.icon-collapse-arrow:before {
  content: "\e900";
  color: #e8ddd2;
}
.icon-email:before {
  content: "\e901";
  color: #f04a00;
}
.icon-tel:before {
  content: "\e902";
  color: #f04a00;
}
.icon-marker:before {
  content: "\e903";
  color: #f04a00;
}
.icon-arrow:before {
  content: "\e904";
  color: #f04a00;
}
.icon-checkmark:before {
  content: "\e90e";
}
.icon-pinterest:before {
  content: "\e90c";
}
.icon-brand2:before {
  content: "\e90c";
}
.icon-social2:before {
  content: "\e90c";
}
.icon-stumbleupon:before {
  content: "\e90d";
}
.icon-brand3:before {
  content: "\e90d";
}
.icon-social3:before {
  content: "\e90d";
}
.icon-lastfm:before {
  content: "\e90f";
}
.icon-brand5:before {
  content: "\e90f";
}
.icon-social5:before {
  content: "\e90f";
}
.icon-linkedin:before {
  content: "\e910";
}
.icon-brand6:before {
  content: "\e910";
}
.icon-social6:before {
  content: "\e910";
}
.icon-reddit:before {
  content: "\e912";
}
.icon-brand8:before {
  content: "\e912";
}
.icon-social8:before {
  content: "\e912";
}
.icon-skype:before {
  content: "\e913";
}
.icon-brand9:before {
  content: "\e913";
}
.icon-social9:before {
  content: "\e913";
}
.icon-soundcloud:before {
  content: "\e914";
}
.icon-brand10:before {
  content: "\e914";
}
.icon-social10:before {
  content: "\e914";
}
.icon-blogger:before {
  content: "\e917";
}
.icon-brand13:before {
  content: "\e917";
}
.icon-social13:before {
  content: "\e917";
}
.icon-flickr:before {
  content: "\e91d";
}
.icon-brand19:before {
  content: "\e91d";
}
.icon-social19:before {
  content: "\e91d";
}
.icon-vimeo:before {
  content: "\e91e";
}
.icon-brand20:before {
  content: "\e91e";
}
.icon-social20:before {
  content: "\e91e";
}
.icon-youtube1:before {
  content: "\e920";
}
.icon-brand22:before {
  content: "\e920";
}
.icon-social22:before {
  content: "\e920";
}
.icon-feed:before {
  content: "\e921";
}
.icon-rss:before {
  content: "\e921";
}
.icon-social23:before {
  content: "\e921";
}
.icon-telegram:before {
  content: "\e924";
}
.icon-brand25:before {
  content: "\e924";
}
.icon-social26:before {
  content: "\e924";
}
.icon-spotify:before {
  content: "\e925";
}
.icon-brand26:before {
  content: "\e925";
}
.icon-social27:before {
  content: "\e925";
}
.icon-whatsapp:before {
  content: "\e926";
}
.icon-brand27:before {
  content: "\e926";
}
.icon-social28:before {
  content: "\e926";
}
.icon-instagram:before {
  content: "\e927";
}
.icon-brand28:before {
  content: "\e927";
}
.icon-social29:before {
  content: "\e927";
}
.icon-facebook:before {
  content: "\e928";
}
.icon-brand29:before {
  content: "\e928";
}
.icon-social30:before {
  content: "\e928";
}
.icon-hangouts:before {
  content: "\e929";
}
.icon-brand30:before {
  content: "\e929";
}
.icon-social31:before {
  content: "\e929";
}
.icon-share:before {
  content: "\e92a";
}
.icon-social32:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e908";
}
.icon-magnifier:before {
  content: "\e908";
}
.icon-magnifying-glass:before {
  content: "\e908";
}
.icon-inspect:before {
  content: "\e908";
}
.icon-find:before {
  content: "\e908";
}
