.references-block {
  .reference-item {
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -4rem;
      right: calc(100% + 4rem);
      background-color: $primary;
      transform: skewX(-10deg);
      transition: right .25s;
    }
    .card-body {
      transition: opacity .2s;
      transition-delay: 0s;
      .btn-wrapper {
        border-top: .1rem solid white;
      }
    }
    @include media-breakpoint-down(lg) {
      &:after {
        right: -4rem;
        background-color: rgba($dark, .5);
      }
      .card-body {
        opacity: 1!important;
        visibility: unset!important;
      }
    }
    &:hover {
      &:after {
        right: -4rem;
      }
      .card-body {
        opacity: 1!important;
        visibility: unset!important;
        transition-delay: .2s;
      }
    }
  }
}