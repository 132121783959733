.numbers-block {
  .value-wrapper {
    margin: -2rem 0;
  }
  p {
    em {
      -webkit-text-stroke: .2rem $primary;
      -webkit-text-fill-color: transparent;
      @include media-breakpoint-up(md) {
        font-size: 8.8rem;
      }
      @include media-breakpoint-between(sm, md) {
        font-size: 7.5rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 6.5rem;
      }
    }
    strong {
      color: $body-color;
      font-size: 3.4rem;
      text-transform: uppercase;
      margin-bottom: 4rem;
      font-family: Barlow, "sans-serif";
      font-style: italic;
      font-weight: 900;
      line-height: 1.09;
    }
  }
  &.bg-primary {
    p {
      em {
        -webkit-text-stroke: .2rem white;
      }
      strong {
        color: white;
      }
    }
  }
}