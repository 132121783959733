html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  &.menu-open {
	overflow: hidden;
  }

  main {
	overflow-x: hidden;

	&.is404 {
	  margin-top: 7rem;
	  @include media-breakpoint-up(lg) {
		margin-top: 12.8rem;
	  }
	  @include media-breakpoint-between(sm, lg) {
		margin-top: 9rem;
	  }
	}
  }
}

.search-form {
  &-sm {
	.input-group {
	  border-bottom: .1rem solid black;

	  input {
		width: 40rem;
		max-width: 100%;
		color: black !important;

		&::placeholder {
		  color: black;
		}
	  }

	  button {
		background-color: transparent;
		border: none;
		-webkit-appearance: none;
		padding: 0 0 0 1rem;
		font-size: 2.6rem;
	  }
	}
  }
}

.socials-wrapper  {
	.icon-container {
		min-width: 22px;
		transform: translateY(4px);
	}
	&-lg {
		.icon-container {
			min-width: 40px;
			i {
				font-size: 2.4rem;
				&.icon-email {
					font-size: 2rem;
				}
				&.icon-marker {
					font-size: 3rem;
				}
			}
		}
	}
}

.accordion-button::after {
  transform: rotate(-180deg) !important;
}

.pagination {
  .page-item {
	position: relative;

	&:after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  background-color: transparent;
	  z-index: -1;
	  transform: skew(-10deg);
	  transition: background-color .2s, color .2s;
	}

	&:not(.active):hover:after, &:not(.active):focus:after, &:not(.active):active:after {
	  background-color: $light;
	}

	&.active {
	  &:after {
		background-color: $primary;
	  }
	}
  }
}

.img-zoom-onhover {
  overflow: hidden;

  .img-zoom-wrapper {
	display: block;
	transform: scale(1);
	transition: transform .4s;
  }

  &:hover {
	.img-zoom-wrapper {
	  transform: scale(1.1);
	}
  }
}

.text-shadow {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.dropdown-con {
  .dropdown-menu {
	position: absolute !important;

	> div {
	  margin-top: -5px;

	  > div {
		-webkit-box-shadow: 0 7px 15px 5px rgba(0, 0, 0, 0.1);
		box-shadow: 0 7px 15px 5px rgba(0, 0, 0, 0.1);
	  }
	}

	opacity: 0;
	visibility: hidden;
	//left: 0;
	top: 63%;
	//min-width: 100%;
	transition: .18s;
	display: block !important;

	@include media-breakpoint-down(lg) {
	  display: none !important;
	}
  }

  &:hover {
	.dropdown-menu {
	  top: 100%;
	  opacity: 1;
	  visibility: unset;
	}
  }

  &.entry-13517 {
	@include media-breakpoint-up(lg) {
	  &.dropdown {
		position: unset !important;

		.dropdown-menu {
		  > div {
			-webkit-box-shadow: 0 7px 15px 5px rgba(0, 0, 0, 0.1);
			box-shadow: 0 7px 15px 5px rgba(0, 0, 0, 0.1);

			> div {
			  -webkit-box-shadow: unset;
			  box-shadow: unset;
			}
		  }
		}
	  }
	}
  }
}

.bg-primary {
  a {
	color: white;
	text-decoration: underline;
  }
}

.show-interval-vp--wrapper {
  .show-interval-vp {
	opacity: 0;
  }

  &.in-viewport {
	.show-interval-vp {
	  animation: .6s cubic-bezier(.25, .25, .25, 1.25) forwards fade-in-up;
	  @for $i from 1 to 20 {
		&:nth-of-type(#{$i}) {
		  animation-delay: .12s + ($i * .15s);
		}
	  }
	}
  }
}

@media (min-width: 768px) {
  .card-columns {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 1.25rem;
	-moz-column-gap: 1.25rem;
	column-gap: 1.25rem;
	orphans: 1;
	widows: 1;
  }
}

.categories-wrapper {
  a {
	transition: background-color .3s, color .3s;

	path {
	  transition: fill .2s;
	}

	&:hover {
	  background-color: $primary !important;

	  path {
		fill: white !important;
	  }

	  span {
		color: white !important;
	  }
	}
  }
}

.img-fx-overlay {
  &:after {
	content: '';
	background-color: $light;
	position: absolute;
	top: 0;
	bottom: 0;
	right: -20%;
	left: -20%;
	transform: skewX(-7deg);
	transition: 1.5s right;
  }

  &.in-viewport {
	visibility: hidden;
	transition-delay: 1.6s;

	&:after {
	  right: 120% !important;
	}
  }
}

a.img--caption {
  right: -.1rem;
  @include media-breakpoint-down(sm) {
	font-size: 1.4rem;
  }
  @include media-breakpoint-up(sm) {
	transform: rotate(90deg) translateX(100%);
	transform-origin: top right;
	transition: background-color .3s, color .3s;
	&:hover {
	  background-color: $primary !important;
	  color: white !important;
	}
  }
}

ul:not(.list-unstyled):not(.pagination) {
  line-height: 2;
  list-style: none;
  margin: 0 0 3rem;
  padding: 0;

  li {
	position: relative;
	padding-left: 4rem;

	&:before {
	  content: "";
	  background-color: $primary;
	  position: absolute;
	  top: 1.2rem;
	  left: .2rem;
	  height: .8rem;
	  width: 1.4rem;
	  transform: skewX(-26deg);
	}
  }
}

.highlights:not(.list-unstyled):not(.pagination) {
  line-height: 2;
  list-style: none;
  margin: 0 0 3rem;
  padding: 0;

  li {
	padding-left: 4rem;

	&:before {
	  content: "\e90e";
	  color: $primary;
	  font-family: 'icomoon' !important;
	  height: 0;
	  left: 0;
	  top: 0;
	  transform: none;
	  width: 0;
	}
  }
}

/*ul li:before {

}*/

::-moz-selection {
  background: $light;
}

::selection {
  background: $light;
}

.bg-light {
  ::-moz-selection {
	background: $primary;
	color: white !important;
  }

  ::selection {
	background: $primary;
	color: white !important;
  }
}

.grid {
  margin: -2rem;

  .grid-item,
  .grid-item--big {
	padding: 2rem;
	width: 100%;

	@include media-breakpoint-up(sm) {
	  width: 50%;
	}
  }

  .grid-item {
	@include media-breakpoint-up(lg) {
	  width: 33.3334%;
	}
  }

  .grid-item--big {
	@include media-breakpoint-up(lg) {
	  width: 66.6667%;
	}
  }
}

.alert {
	p {
		margin-bottom: 0!important;
	}
}