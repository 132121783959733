.homeBanner{
  @include media-breakpoint-up(lg) {
    height: 100vh;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -20%;
    bottom: -20%;
    transform: translateY(0) skewY(-3deg);
    background-color: $light;
    z-index: 10;
    transition: transform 1s;
  }

  h1 {
    opacity: 0;
    transform: translateY(34px);
    transition: opacity .6s ease .25s, transform 1.4s ease .25s;
  }
  .homeBanner--img-slider {
    transition: transform 3s ease;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(#000, .1);
      z-index: 1;
    }

    img {
      height: 100vh;

      @include media-breakpoint-between(sm, lg) {
        min-height: 75rem;
        max-height: 75rem;
      }

      @include media-breakpoint-down(sm) {
        min-height: 50rem;
        max-height: 50rem;
      }
    }
  }

  .scroll--item {
    transform: rotate(90deg) translateX(1px);
    transform-origin: top right;
    transition: background-color .3s, color .3s;
    > a.scroll--item-gsap {
      font-weight: 600;
      transition: margin-top .7s .5s, background-color .2s, color .2s;
      .icon-arrow {
        font-size: 1.2rem;
      }
    }
    &:hover {
      a {
        background-color: $primary!important;
      }
      span, :before {
        color: white!important;
      }
    }
  }
}

body {
  &.is-loaded {
    .homeBanner {
      &:before {
        transform: translateY(-115%) skewY(-3deg);
      }
      h1 {
        opacity: 1;
        transform: translateY(0);
      }
      .homeBanner--img-slider {
        transform: scale(1) !important;
      }

      .scroll--item {
        > .scroll--item-gsap {
          margin-top: 0!important;
        }
      }
    }
  }
}