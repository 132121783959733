.quote-block {
  span.quote {
    font-family: 'Manrope';
    color: $primary;
    font-size: 50rem;
    line-height: .5;
  }
  img {
    @include media-breakpoint-down(lg) {
      max-width: 25%;
    }
  }
}

.quotes {
  &-icon {
    @include media-breakpoint-up(sm) {
      transform: translateX(3rem);
    }
  }
}