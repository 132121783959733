.videos-block {
  a {
    .btn-play {
      transform: scale(1);
      transition: transform .2s;
    }
    &:hover {
      .btn-play {
        transform: scale(1.2);
      }
    }
  }
}