header {
  background-color: transparent;
  transition: margin-top .3s linear .8s, background-color .18s, border-bottom .2s;
  border-bottom: 1px solid rgba(255, 255, 255, .3);

  a {
    &.nav-link {
      border-bottom: .4rem solid transparent;
      transition: border-bottom-color .2s;
    }
    &:not(.nav-link) {
      transition: color .2s;
      &:hover {
        color: $primary!important;
      }
    }
  }

  .nav-item {
    .nav-link {
      &:hover, &.active {
        border-bottom: .4rem solid white;
        transition: border-bottom-color .15s;
      }
    }
  }

  .header-logo--container {
    position: relative;
    &:before {
      content: "";
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      bottom: 100%;
      height: 0.1rem;
      width: 25rem;
      left: -132px;
      transform: rotate(295deg);
      transition: width 0.4s;
    }
  }
  .categories-nav-wrapper {
    z-index: -1;
    transition: .2s;
    top: 100%;
  }
  &.header--dark {
    @include media-breakpoint-up(lg) {
      .categories-nav-wrapper {
        opacity: 1 !important;
        visibility: unset !important;
      }
    }
  }
  &.header--notTop {
    a {
      &.nav-link {
        padding: 2rem 0;
      }
    }
  }
  &.header--notTop, &.header--dark {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    transform: translateY(0);
    transition: background-color .4s, transform .4s, border-bottom .2s;

    &.header--unpinned {
      transform: translateY(-100%);
    }

    .nav-item {
      .nav-link {
        margin-bottom: -.1rem;
        &:hover, &.active {
          border-bottom: .4rem solid $primary;
        }
      }
    }
    a, button, i:before {
      color: $body-color!important;
      transition: color .2s;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $dark;
    }
    .header-logo--container {
      &:before {
        content: '';
        width: 12rem;
        transition: width .6s;
      }
      &--white {
        display: none;
      }
      &--black {
        display: block!important;
      }
    }
  }
  a.dropdown-item {
    &.active {
      color: white!important;
    }
  }
  &.is-loaded {
    margin-top: 0!important;
  }

  .language-dropdown {
    button {
      background-color: transparent;
      border: 0;
      i {
        font-size:12px;
        transform:rotate(90deg)
      }
    }
    .dropdown-menu {
      min-width: unset;
    }
  }
}

//mobile nav
@include media-breakpoint-down(lg) {
  header {
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after, .header-logo--container {
      z-index: 16;
    }
    nav {
      .nav-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: block!important;
        z-index: 15;
        background-color: white;
        visibility: hidden;
        opacity: 0;
        transition: .2s opacity;
        transition-delay: .22s;
        a {
          font-size: 3rem;
          color: $body-color!important;
          padding: 0.5rem 1rem!important;
        }
      }
    }
  }
  .menu-open {
    header {
      &.header--notTop.header--pinned {
        transform: unset!important;
      }
      .header-logo--container {
        &.header-logo--container--white {
          display: none!important;
        }
        &.header-logo--container--black {
          display: block!important;
        }
      }
      nav {
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
          background-color: $dark!important;
        }
        .nav-menu {
          visibility: unset;
          opacity: 1;
        }
      }
    }
  }

  .header-logo--container {
    img {
      width: 18rem;
    }
  }
}